<template>
    <div class="padding-container">
        <div class="box-manifest">
            <h5 class="fw-bold">Daftar Pricing</h5>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-4 m-mx-0 m-mt-2">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari kota asal/tujuan"
                                v-model="search"
                                @keyup.enter="getPricings()"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="getPricings()"
                            >
                                Cari
                            </button>
                        </div>
                        <span class="search-icon left">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="m-mx-0 m-mt-2 me-3">
                    <button
                        class="btn btn-outline-yellow btn-filter px-3"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-4">
                        <label class="fw-bold mb-1">Kota Asal</label>
                        <multiselect
                            v-model="origin"
                            :options="regencies"
                            label="name"
                            track-by="id"
                            placeholder="Cari kota asal"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                            <template v-slot:caret>
                                <img
                                    :src="
                                        origin
                                            ? magnifierActiveIcon
                                            : magnifierIcon
                                    "
                                    alt="icon"
                                    style="
                                        width: 20px;
                                        height: 20px;
                                        position: absolute;
                                        left: 12px;
                                        top: 42%;
                                        transform: translateY(-50%);
                                    "
                                />
                            </template>
                        </multiselect>
                    </div>
                    <div class="col-md-8">
                        <label class="fw-bold mb-1">Kota Tujuan</label>
                        <div class="row">
                            <div class="col-md-6">
                                <multiselect
                                    v-model="destination"
                                    :options="regencies"
                                    label="name"
                                    track-by="id"
                                    placeholder="Cari kota tujuan"
                                    class="multi-form-custom"
                                    :show-labels="false"
                                >
                                    <template v-slot:caret>
                                        <img
                                            :src="
                                                destination
                                                    ? magnifierActiveIcon
                                                    : magnifierIcon
                                            "
                                            alt="icon"
                                            style="
                                                width: 20px;
                                                height: 20px;
                                                position: absolute;
                                                left: 12px;
                                                top: 42%;
                                                transform: translateY(-50%);
                                            "
                                        />
                                    </template>
                                </multiselect>
                            </div>
                            <div class="col-md-4">
                                <div class="d-flex">
                                    <button
                                        class="btn btn-outline-yellow px-5 me-3"
                                        :disabled="
                                            origin == null ||
                                            destination == null
                                        "
                                        @click="clearFilter()"
                                    >
                                        Clear
                                    </button>
                                    <button
                                        class="btn btn-green px-5"
                                        :disabled="
                                            origin == null ||
                                            destination == null
                                        "
                                        @click="getPricings()"
                                    >
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center mt-4">
                <div>
                    Menampilkan
                    <span class="fw-bold">{{ pageStart }}-{{ pageEnd }}</span>
                    Data dari
                    <span class="fw-bold">{{ pricings.list_data.length }}</span>
                    Data
                </div>
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <div class="me-2">Baris per halaman</div>
                        <select
                            class="form-control form-select w-select outline-yellow"
                            v-model="limit"
                            @change="onLimitChange"
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="isLoader">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Kota Asal
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Tujuan
                                </th>
                                <th class="text-center" scope="col">
                                    Jenis Layanan
                                </th>
                                <th class="text-center" scope="col">
                                    Harga Pengiriman
                                    <img
                                        src="../assets/sort.svg"
                                        alt="sort"
                                        class="ms-2 cursor-pointer"
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(pricing, index) in pricings.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>adsdad</td>
                                <td>dasdasd</td>
                                <td>
                                    <p
                                        class="rounded-pill service text-center mb-0"
                                    >
                                        Pengiriman Motor
                                    </p>
                                </td>
                                <td>
                                    <div
                                        class="d-flex justify-content-end align-items-center cursor-pointer"
                                        @click="openSidebar()"
                                    >
                                        <p class="text-link mb-0 me-2">
                                            Rp 12.000
                                        </p>
                                        <img
                                            src="../assets/edit.svg"
                                            width="20"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center mt-5" v-if="pricings.length === 0">
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar pricing
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <b-pagination-nav
                        v-if="pricings.list_data.length > 0"
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="getPricings()"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
        <div
            class="sidebar-overlay"
            v-if="isSidebarOpen"
            @click="closeSidebar()"
        ></div>
        <div class="sidebar" :class="{ 'sidebar-open': isSidebarOpen }">
            <div
                class="d-flex justify-content-between align-items-center p-3 border-bottom"
            >
                <h5 class="mb-0 fw-bold">Edit Pricing</h5>
                <div
                    type="button"
                    class="btn-close"
                    @click="closeSidebar(0)"
                ></div>
            </div>
            <div class="p-3">
                <label class="form-label"
                    >Harga Pengiriman <span class="text-yellow">*</span></label
                >
                <div class="input-group mb-3">
                    <span class="input-group-text">Rp</span>
                    <input
                        type="text"
                        class="form-control input-sidebar"
                        placeholder="Masukkan harga pengiriman"
                        v-model="price"
                    />
                </div>
                <hr />
                <h5 class="mb-3 fw-bold">Data Pricing Vendor</h5>
                <div class="d-flex justify-content-center mt-5">
                    <img src="../assets/empty.svg" alt="empty" />
                </div>
                <p class="text-gray text-center mt-5">
                    Tidak ditemukan data pricing dari vendor
                </p>
                <div class="mb-4">
                    <div
                        class="d-flex justify-content-between header-sidebar px-3 py-2"
                    >
                        <div class="d-flex align-items-center">
                            <img
                                src="../assets/origin-location.svg"
                                alt="origin location"
                                class="me-2"
                            />
                            Kota Bandung
                        </div>
                        <img
                            src="../assets/arrow-location.svg"
                            alt="arrow location"
                        />
                        <div class="d-flex align-items-center">
                            <img
                                src="../assets/destination-location.svg"
                                alt="destination location"
                                class="me-2"
                            />
                            Kota Bandung
                        </div>
                    </div>
                    <div class="body-sidebar px-3 py-2">
                        <div
                            class="d-flex justify-content-between align-items-center my-2"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    src="../assets/truck-location.svg"
                                    alt="truck location"
                                    class="me-2"
                                />
                                <p class="mb-0">Logi Xpress</p>
                            </div>
                            <p class="text-primary mb-0">Rp 8.000</p>
                        </div>
                        <div
                            class="d-flex justify-content-between align-items-center my-2"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    src="../assets/truck-location.svg"
                                    alt="truck location"
                                    class="me-2"
                                />
                                <p class="mb-0">Logi Xpress</p>
                            </div>
                            <p class="text-primary mb-0">Rp 8.000</p>
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <div
                        class="d-flex justify-content-between header-sidebar px-3 py-2"
                    >
                        <div class="d-flex align-items-center">
                            <img
                                src="../assets/destination-location.svg"
                                alt="destination location"
                                class="me-2"
                            />
                            Kota Bandung
                        </div>
                        <img
                            src="../assets/arrow-location.svg"
                            alt="arrow location"
                        />
                        <div class="d-flex align-items-center">
                            <img
                                src="../assets/destination-location.svg"
                                alt="destination location"
                                class="me-2"
                            />
                            Kota Bandung
                        </div>
                    </div>
                    <div class="body-sidebar px-3 py-2">
                        <div
                            class="d-flex justify-content-between align-items-center my-2"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    src="../assets/truck-location.svg"
                                    alt="truck location"
                                    class="me-2"
                                />
                                <p class="mb-0">Logi Xpress</p>
                            </div>
                            <p class="text-primary mb-0">Rp 8.000</p>
                        </div>
                        <div
                            class="d-flex justify-content-between align-items-center my-2"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    src="../assets/truck-location.svg"
                                    alt="truck location"
                                    class="me-2"
                                />
                                <p class="mb-0">Logi Xpress</p>
                            </div>
                            <p class="text-primary mb-0">Rp 8.000</p>
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <div
                        class="d-flex justify-content-between header-sidebar px-3 py-2"
                    >
                        <div class="d-flex align-items-center">
                            <img
                                src="../assets/destination-location.svg"
                                alt="destination location"
                                class="me-2"
                            />
                            Kota Bandung
                        </div>
                        <img
                            src="../assets/arrow-location.svg"
                            alt="arrow location"
                        />
                        <div class="d-flex align-items-center">
                            <img
                                src="../assets/origin-location.svg"
                                alt="origin location"
                                class="me-2"
                            />
                            Kota Bandung
                        </div>
                    </div>
                    <div class="body-sidebar px-3 py-2">
                        <div
                            class="d-flex justify-content-between align-items-center my-2"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    src="../assets/truck-location.svg"
                                    alt="truck location"
                                    class="me-2"
                                />
                                <p class="mb-0">Logi Xpress</p>
                            </div>
                            <p class="text-primary mb-0">Rp 8.000</p>
                        </div>
                        <div
                            class="d-flex justify-content-between align-items-center my-2"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    src="../assets/truck-location.svg"
                                    alt="truck location"
                                    class="me-2"
                                />
                                <p class="mb-0">Logi Xpress</p>
                            </div>
                            <p class="text-primary mb-0">Rp 8.000</p>
                        </div>
                    </div>
                </div>
                <hr />
                <button
                    class="btn btn-primary w-100"
                    :disabled="price === null"
                    @click="modalConfirm = true"
                >
                    Simpan Data
                </button>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data pricing akan disimpan
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="editPricing()"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data pricing sudah disimpan
                    </h4>
                </div>
                <div class="mt-4">
                    <div
                        class="btn btn-primary w-100 btn-lg"
                        @click="
                            isSidebarOpen = false;
                            modalSuccess = false;
                            getPricings();
                        "
                    >
                        Tutup
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";
import magnifierDisabled from "../assets/magnifier-disabled.svg";
import magnifier from "../assets/magnifier.svg";

export default {
    components: {
        Multiselect,
        ListLoader,
    },
    data() {
        return {
            modalConfirm: false,
            modalSuccess: false,
            price: null,
            isSidebarOpen: false,
            pageStart: 0,
            pageEnd: 0,
            limit: 5,
            totalPage: 0,
            currentPage: 1,
            magnifierIcon: magnifierDisabled,
            magnifierActiveIcon: magnifier,
            isFilter: false,
            search: null,
            origin: null,
            destination: null,
            regencies: null,
            isLoader: false,
            pricings: {
                list_data: [],
            },
        };
    },
    created() {
        this.getRegency();
        this.getPricings();
    },
    methods: {
        openSidebar() {
            this.isSidebarOpen = true;
        },
        closeSidebar() {
            this.isSidebarOpen = false;
            this.price = null;
        },
        onLimitChange() {
            this.currentPage = 1;
            this.getPricings();
        },
        clearFilter() {
            this.origin = null;
            this.destination = null;
            this.getPricings();
        },
        getPricings() {
            let payload = {
                page: this.currentPage,
                search: this.search ? this.search : null,
                limit: this.limit ? this.limit : null,
                origin: this.origin ? this.origin : null,
                destination: this.destination ? this.destination : null,
            };
            this.isLoader = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/manifest`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.isLoader = false;
                    this.pricings = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    const totalItems = this.pricings.list_data.length;
                    this.pageStart =
                        totalItems === 0
                            ? 0
                            : (this.currentPage - 1) * this.limit + 1;
                    this.pageEnd = Math.min(
                        this.pageStart + this.limit - 1,
                        totalItems
                    );
                })
                .catch((err) => {
                    this.isLoader = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.regencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        editPricing() {
            this.modalConfirm = false;
            this.modalSuccess = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.modal-vue {
    position: fixed;
    z-index: 9999;
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
hr {
    color: #dee2e6 !important;
    margin: 1.5rem 0;
}
.input-sidebar {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1040;
}

.sidebar {
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: -100%;
    width: 500px;
    height: 100vh;
    background-color: #f9fafb;
    z-index: 1050;
    transition: right 0.3s ease-in-out;
    box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
}

.sidebar-open {
    right: 0;
}
.btn-green:disabled {
    background: #e0e6eb !important;
    border: none !important;
    color: #849bae !important;
}
td {
    background-color: transparent !important;
}
th {
    background-color: #f3f5f7 !important;
}
.service {
    background-color: #cce7ff !important;
    border: 1px solid #99d0ff !important;
    color: #005299 !important;
}
::v-deep .page-link {
    background-color: #f3f5f7 !important;
}
::v-deep .page-item.active .page-link {
    background-color: #1291ff !important;
}
::v-deep .multi-form-custom {
    .multiselect__tags {
        padding-left: 40px !important;
    }
    .multiselect__select {
        padding: 0;
    }
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.outline-yellow:focus {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
</style>
