<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_loading_data"></div>
        <div id="loader" v-if="is_loading_data"></div>
        <notifications group="foo" position="top right" />
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Prediksi</h3>
            </div>
            <div class="row">
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-1">
                        Jenis Layanan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <select
                        class="form-control form-select"
                        v-model="req.service"
                    >
                        <option value="">Pilih jenis layanan</option>
                        <option value="pengiriman_barang">
                            Pengiriman Barang
                        </option>
                        <option value="pengiriman_motor">Kirim Motor</option>
                        <option value="pengiriman_mobil">Kirim Mobil</option>
                        <option value="sewa_container_20ft">
                            Sewa Kontainer 20 Feet
                        </option>
                        <option value="sewa_container_40ft">
                            Sewa Kontainer 40 Feet
                        </option>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kab. Asal
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="regency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kab. Tujuan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="destination"
                        :options="destinations"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-md-12 mt-2">
                    <label class="fw-bold mb-1">
                        Detail Alamat
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        class="form-control"
                        placeholder="Masukkan detail alamat"
                        v-model="req.address"
                    ></textarea>
                </div>
                <div
                    class="mt-3 col-md-6"
                    v-if="req.service && req.service === 'pengiriman_motor'"
                >
                    <label class="mb-2"
                        >Tipe Motor <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="req.bikeType"
                        @input="handleTypeSearch()"
                        @focus="handleFocus()"
                        placeholder="Masukkan tipe motor"
                    />
                    <div class="suggestions-container" v-if="showSuggestions">
                        <p class="suggestion-title">
                            Pilih atau Tambahkan Tipe Motor
                        </p>
                        <div class="suggestion-item-container">
                            <div
                                class="suggestion-item-none"
                                v-if="typeSuggestions.length === 0"
                            >
                                <i>Tipe motor tidak ditemukan</i>
                            </div>
                            <div
                                v-else
                                v-for="(suggestion, index) in typeSuggestions"
                                :key="index"
                                class="suggestion-item"
                                @click="selectType(suggestion)"
                            >
                                {{ suggestion.name }}
                            </div>
                        </div>
                        <hr class="hr-suggestion" />
                        <p class="suggestion-title d-flex align-items-center">
                            <span>Tambahkan:</span>
                            <span
                                v-if="isAddVehicle"
                                class="spinner-border text-primary ms-2"
                                role="status"
                            >
                            </span>
                            <span
                                v-else
                                class="text-suggestion-dark of-y pe-3 ps-1 cursor-pointer"
                                @click="createNewVehicle('bike')"
                                >{{ req.bikeType }}</span
                            >
                        </p>
                    </div>
                </div>
                <div
                    class="col-md-6 mt-3"
                    v-if="req.service && req.service === 'pengiriman_motor'"
                >
                    <label class="mb-2"
                        >Kapasitas Motor
                        <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        v-model="req.capacity"
                        :options="capacities"
                        label="name"
                        track-by="id"
                        placeholder="Pilih kapasitas motor"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div
                    class="mt-3 col-md-6"
                    v-if="req.service && req.service === 'pengiriman_mobil'"
                >
                    <label class="mb-2"
                        >Tipe Mobil <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="req.carType"
                        @input="handleCarTypeSearch()"
                        @focus="handleCarFocus()"
                        placeholder="Masukkan tipe mobil"
                    />
                    <div
                        class="suggestions-container"
                        v-if="showCarSuggestions"
                    >
                        <p class="suggestion-title">
                            Pilih atau Tambahkan Tipe Mobil
                        </p>
                        <div class="suggestion-item-container">
                            <div
                                class="suggestion-item-none"
                                v-if="carTypeSuggestions.length === 0"
                            >
                                <i>Tipe mobil tidak ditemukan</i>
                            </div>
                            <div
                                v-else
                                v-for="(
                                    suggestion, index
                                ) in carTypeSuggestions"
                                :key="index"
                                class="suggestion-item"
                                @click="selectCarType(suggestion)"
                            >
                                {{ suggestion.name }}
                            </div>
                        </div>
                        <hr class="hr-suggestion" />
                        <p class="suggestion-title d-flex align-items-center">
                            <span>Tambahkan:</span>
                            <span
                                v-if="isAddCarVehicle"
                                class="spinner-border text-primary ms-2"
                                role="status"
                            >
                            </span>
                            <span
                                v-else
                                class="text-suggestion-dark of-y pe-3 ps-1 cursor-pointer"
                                @click="createNewVehicle('car')"
                                >{{ req.carType }}</span
                            >
                        </p>
                    </div>
                </div>
                <div
                    class="col-md-12 mt-3"
                    v-if="req.service && req.service === 'pengiriman_barang'"
                >
                    <label class="fw-bold mb-1"> Dimensi Barang </label>
                    <div class="row">
                        <div class="col-md-4">
                            <label class="fw-bold mb-1"> Panjang </label>
                            <div class="input-group mb-0">
                                <money
                                    v-model="req.length"
                                    v-bind="price_format"
                                    class="form-control form-format"
                                    placeholder="Panjang"
                                >
                                </money>
                                <span class="input-group-text">Cm</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="fw-bold mb-1"> Lebar </label>
                            <div class="input-group mb-0">
                                <money
                                    v-model="req.width"
                                    v-bind="price_format"
                                    class="form-control form-format"
                                    placeholder="Lebar"
                                >
                                </money>
                                <span class="input-group-text">Cm</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="fw-bold mb-1"> Tinggi </label>
                            <div class="input-group mb-0">
                                <money
                                    v-model="req.height"
                                    v-bind="price_format"
                                    class="form-control form-format"
                                    placeholder="Tinggi"
                                >
                                </money>
                                <span class="input-group-text">Cm</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-md-6 mt-3"
                    v-if="req.service && req.service === 'pengiriman_barang'"
                >
                    <label class="fw-bold mb-1">
                        Berat
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <money
                            v-model="req.weight"
                            v-bind="price_format"
                            class="form-control form-format"
                            placeholder="Berat"
                        >
                        </money>
                        <span class="input-group-text">Kg</span>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Harga Pengiriman
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">Rp</span>
                        <money
                            v-model="req.price"
                            v-bind="price_format"
                            class="form-control form-phone"
                            placeholder="Masukkan harga pengiriman"
                        >
                        </money>
                    </div>
                </div>
                <div
                    class="col-md-12 mt-3"
                    v-if="
                        this.req.service !== 'sewa_container_20ft' &&
                        this.req.service !== 'sewa_container_40ft'
                    "
                >
                    <label class="fw-bold mb-1">
                        Deskripsi
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        class="form-control"
                        placeholder="Isi deskripsi"
                        v-model="req.description"
                    ></textarea>
                </div>
            </div>
            <div class="d-flex mt-5">
                <div class="ms-auto">
                    <div class="d-flex">
                        <a
                            href="javascript:void(0)"
                            @click="$router.go(-1)"
                            class="btn btn-outline-yellow px-5 btn-lg me-3"
                        >
                            Batal
                        </a>
                        <button
                            type="button"
                            :disabled="!isFormValid"
                            class="btn btn-primary px-5 btn-lg"
                            @click="storeConfirmation()"
                        >
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalConfirmStorePerdiction"></div>
            <div class="modal-body-chat vsm" v-if="modalConfirmStorePerdiction">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Informasi Prediksi Akan Diperbaharui
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirmStorePerdiction = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storePrediction()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_save"
                            ></span>
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Informasi Prediksi Berhasil Diperbaharui
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/followup/all"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";
import { Money } from "v-money";
import $ from "jquery";

export default {
    components: {
        Multiselect,
        Money,
    },
    name: "PredictionEdit",
    data() {
        return {
            carTypeSuggestions: [],
            showCarSuggestions: false,
            isAddCarVehicle: false,
            typeSuggestions: [],
            showSuggestions: false,
            searchTimeout: null,
            capacities: [
                {
                    name: "100 CC - 149 CC",
                    id: "lower",
                },
                {
                    name: "150 CC - 249 CC",
                    id: "middle",
                },
                {
                    name: "Diatas 250 CC",
                    id: "high",
                },
            ],
            vehicleSearch: "",
            isAddVehicle: false,
            specialist: null,
            specialists: [],
            regency: {
                id: "",
                name: "",
            },
            destination: {
                id: "",
                name: "",
            },
            req: {
                bikeType: "",
                carType: "",
                capacity: "",
                activity: "",
                specialist_id: null,
                priority: "",
                description_activity: "",
                is_prediction: null,
                service: "",
                address: "",
                height: "",
                length: "",
                width: "",
                weight: "",
                price: "",
                description: "",
            },
            regencies: [],
            destinations: [],
            is_save: false,
            followup_id: null,
            modalConfirmStore: false,
            modalSuccess: false,
            modalConfirmStorePerdiction: false,
            price_format: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 0,
                masked: false,
            },
            is_loading_data: false,
        };
    },
    created() {
        this.getGeo();
        this.getSpecialists();
        document.addEventListener("click", (e) => {
            const carSuggestionsContainer = document.querySelector(
                ".suggestions-container"
            );
            const carTypeInput = document.querySelector(
                'input[placeholder="Masukkan tipe mobil"]'
            );

            if (carSuggestionsContainer && carTypeInput) {
                if (
                    !carSuggestionsContainer.contains(e.target) &&
                    !carTypeInput.contains(e.target)
                ) {
                    this.showCarSuggestions = false;
                }
            }
        });
        document.addEventListener("click", (e) => {
            const suggestionsContainer = document.querySelector(
                ".suggestions-container"
            );
            const typeInput = document.querySelector(
                'input[placeholder="Masukkan tipe motor"]'
            );

            if (suggestionsContainer && typeInput) {
                if (
                    !suggestionsContainer.contains(e.target) &&
                    !typeInput.contains(e.target)
                ) {
                    this.showSuggestions = false;
                }
            }
        });
    },
    computed: {
        isFormValid() {
            // Basic validation that applies to all services
            const baseValidation =
                this.regency !== null &&
                this.destination !== null &&
                this.req.address !== "" &&
                this.req.price !== "" &&
                this.req.service !== "" &&
                this.regency !== undefined &&
                this.destination !== undefined &&
                this.req.address !== undefined &&
                this.req.price !== undefined &&
                this.req.service !== undefined;

            // Service specific validation
            switch (this.req.service) {
                case "pengiriman_barang":
                    return (
                        baseValidation &&
                        this.req.weight !== "" &&
                        this.req.description !== "" &&
                        this.req.weight !== undefined &&
                        this.req.description !== undefined
                    );

                case "pengiriman_motor":
                    return (
                        baseValidation &&
                        this.req.bikeType !== "" &&
                        this.req.capacity !== "" &&
                        this.req.description !== "" &&
                        this.req.bikeType !== undefined &&
                        this.req.capacity !== undefined &&
                        this.req.description !== undefined
                    );

                case "pengiriman_mobil":
                    return (
                        baseValidation &&
                        this.req.carType !== "" &&
                        this.req.description !== "" &&
                        this.req.carType !== undefined &&
                        this.req.description !== undefined
                    );

                case "sewa_container_20ft":
                case "sewa_container_40ft":
                    return baseValidation;

                default:
                    return false;
            }
        },
    },
    mounted() {
        $(document).ready(function () {
            $("#quantity").keypress(function (e) {
                if (
                    e.which != 8 &&
                    e.which != 0 &&
                    (e.which < 48 || e.which > 57)
                ) {
                    $("#errmsg").html("Numbers Only").show().fadeOut("slow");
                    return false;
                }
            });
        });
    },
    methods: {
        handleCarFocus() {
            this.handleCarTypeSearch();
            this.showCarSuggestions = true;
        },
        handleCarTypeSearch() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = setTimeout(() => {
                this.getListVehicle("car");
            }, 300);
        },
        selectCarType(suggestion) {
            this.req.carType = suggestion.name;
            this.showCarSuggestions = false;
        },
        handleFocus() {
            this.handleTypeSearch();
            this.showSuggestions = true;
        },
        createNewVehicle(type) {
            if (type === "car") {
                this.isAddCarVehicle = true;
            } else {
                this.isAddVehicle = true;
            }
            const payload = {
                name: type === "car" ? this.req.carType : this.req.bikeType,
                type: type,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vehicles/create`,
                payload,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    if (type === "car") {
                        this.handleCarTypeSearch();
                        this.isAddCarVehicle = false;
                    } else {
                        this.isAddVehicle = false;
                        this.handleTypeSearch();
                    }
                })
                .catch((err) => {
                    if (type === "car") {
                        this.isAddCarVehicle = false;
                    } else {
                        this.isAddVehicle = false;
                    }
                    this.showSuggestions = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        handleTypeSearch() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = setTimeout(() => {
                this.getListVehicle("bike");
            }, 300);
        },
        selectType(suggestion) {
            this.req.bikeType = suggestion.name;
            this.showSuggestions = false;
        },
        getListVehicle(type) {
            const payload = {
                search: type === "car" ? this.req.carType : this.req.bikeType,
                type: type,
            };

            Api.get(`${process.env.VUE_APP_SERVICE_URL}/vehicles`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    if (type === "car") {
                        this.carTypeSuggestions = res.data.data;
                    } else {
                        this.typeSuggestions = res.data.data;
                    }
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        inputNumber() {
            this.req.length = this.req.length.replace(/[^\d]/g, "");
            this.req.width = this.req.width.replace(/[^\d]/g, "");
            this.req.height = this.req.height.replace(/[^\d]/g, "");
            this.req.weight = this.req.weight.replace(/[^\d]/g, "");
        },
        getGeo() {
            this.is_loading_data = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.regencies = data;
                    this.destinations = data;
                    this.is_loading_data = false;
                    this.getDetailPrediction();
                })
                .catch((err) => {
                    this.is_loading_data = false;
                    console.log(err);
                });
        },
        getSpecialists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/user`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getDetailPrediction() {
            this.is_loading_data = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/predictions/detail?followup_id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )

                .then((res) => {
                    if (res.data.data != null) {
                        this.req = res.data.data;
                        this.req.length = res?.data?.data?.dimension?.length;
                        this.req.width = res?.data?.data?.dimension?.width;
                        this.req.height = res?.data?.data?.dimension?.height;
                        this.req.bikeType = res?.data?.data?.vehicle;
                        this.req.carType = res?.data?.data?.vehicle;
                        this.req.capacity = res.data.data.cc
                            ? {
                                  id: res.data.data.cc,
                                  name: this.capacities.find(
                                      (cap) => cap.id === res.data.data.cc
                                  )?.name,
                              }
                            : null;
                        this.regency.name = res?.data?.data?.origin;
                        this.regency.id = res.data.data.origin_id;
                        this.destination.name = res?.data?.data?.dest;
                        this.destination.id = res?.data?.data?.dest_id;
                    } else {
                        this.regency = null;
                        this.destination = null;
                    }
                    this.is_loading_data = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_loading_data = false;
                });
        },
        storeConfirmation() {
            this.modalConfirmStorePerdiction = true;
        },
        storePrediction() {
            this.is_save = true;
            let data = {
                followup_id: this.$route.params.id,
                service: this.req.service,
                origin_regency_id: this.regency?.id,
                destination_regency_id: this.destination?.id,
                address: this.req.address,
                price: parseFloat(this.req.price),
            };
            if (this.req.service === "pengiriman_barang") {
                data = {
                    ...data,
                    length: parseFloat(this.req.length),
                    width: parseFloat(this.req.width),
                    height: parseFloat(this.req.height),
                    weight: parseFloat(this.req.weight),
                    description: this.req.description,
                };
            } else if (this.req.service === "pengiriman_mobil") {
                data = {
                    ...data,
                    vehicle: this.req.carType,
                    description: this.req.description,
                };
            } else if (this.req.service === "pengiriman_motor") {
                data = {
                    ...data,
                    vehicle: this.req.bikeType,
                    description: this.req.description,
                    cc: this.req.capacity.id,
                };
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/predictions/store`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.modalSuccess = true;
                    this.modalConfirmStorePerdiction = false;
                    this.is_save = false;
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                    this.is_save = false;
                });
        },
    },
    beforeDestroy() {
        document.removeEventListener("click");
    },
};
</script>

<style scoped>
.of-y::-webkit-scrollbar {
    display: none;
}
hr.hr-suggestion {
    margin: 0;
    margin-top: 5px;
}
.suggestions-container {
    position: static;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #c1cdd7;
    border-radius: 7px;
    margin-top: 2px;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestion-title {
    padding: 8px 12px !important;
    margin-bottom: 0 !important;
    font-size: 12px;
    color: #a3b4c2;
}

.text-suggestion-dark {
    color: #212529;
    font-weight: 500;
}

.suggestion-item-container {
    max-height: 200px;
    overflow-y: auto;
}

.suggestion-item {
    padding: 5px 24px;
    cursor: pointer;

    &:hover {
        background-color: #f3f5f7;
    }
}
.suggestion-item-none {
    padding: 5px 24px;
}

.hr-suggestion {
    margin: 0;
    margin-top: 5px;
}
</style>
