import { render, staticRenderFns } from "./ManifestCreate.vue?vue&type=template&id=2fd9f926&scoped=true"
import script from "./ManifestCreate.vue?vue&type=script&lang=js"
export * from "./ManifestCreate.vue?vue&type=script&lang=js"
import style0 from "./ManifestCreate.vue?vue&type=style&index=0&id=2fd9f926&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fd9f926",
  null
  
)

export default component.exports