import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import NewPassword from "../views/NewPassword.vue";
import ForgotOtp from "../views/Otp.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import DashboardTally from "../views/dashboard/DashboardTally.vue";
import DashboardHeadDriver from "../views/dashboard/DashboardHeadDriver.vue";
import Profile from "../views/profile/Index";
import crmAssignCroDetail from "../views/crm/ProspectDetail.vue";
import crmCustomers from "../views/crm/Customers.vue";
import crmCustomersOrdered from "../views/crm/CustomersOrdered.vue";
import crmCustomersRegistered from "../views/crm/CustomerRegistered.vue";
import crmCustomersLeads from "../views/crm/CustomersLeads.vue";
import crmCustomersLeadsDetail from "../views/crm/CustomersLeadsDetail.vue";
import crmCustomersForm from "../views/crm/CustomersForm.vue";
import crmCustomersFormEdit from "../views/crm/CustomersFormEdit.vue";
import crmCustomerLeadsProspect from "../views/crm/CustomerLeadsProspect.vue";
import crmPredictionEdit from "../views/crm/PredictionEdit.vue";
import crmProspect from "../views/crm/Prospect.vue";
import crmProspectAll from "../views/crm/ProspectAll.vue";
import crmProspectToday from "../views/crm/ProspectToday.vue";
import crmProspectTomorrow from "../views/crm/ProspectTomorrow.vue";
import crmProspectNextTwoDays from "../views/crm/ProspectNextTwoDays.vue";
import crmProspectNextWeek from "../views/crm/ProspectNextWeek.vue";
import crmProspectNextMonth from "../views/crm/ProspectNextMonth.vue";
import crmProspectNeedFollowUp from "../views/crm/ProspectNeedFollowUp.vue";
import crmProspectDealt from "../views/crm/ProspectDealt.vue";
import crmProspectDetail from "../views/crm/CustomersLeadsDetail.vue";
import crmProspectLost from "../views/crm/ProspectLost.vue";
import crmProfile from "../views/crm/CrmProfile.vue";
import crmProfileEdit from "../views/crm/CrmProfileEdit.vue";
import NotificationPage from "../views/Notification";
import AssignmentDriver from "../views/crm/AssignmentDriver.vue";
import AssignmentList from "../views/crm/AssignmentList.vue";
import AssignmentListDetail from "../views/crm/AssignmentListDetail.vue";
import PickupDriver from "../views/crm/PickupDriver.vue";
import DealOrder from "../views/DealOrder.vue";
import LostOrder from "../views/LostOrder.vue";
import Tally from "../views/Tally.vue";
import WalkIn from "../views/WalkIn.vue";
import WalkInCreate from "../views/WalkInCreate.vue";
import WalkInDetail from "../views/WalkInDetail.vue";
import WalkInEdit from "../views/WalkInEdit.vue";
import TallyOffLoading from "../views/TallyOffLoading.vue";
import TallyDetail from "../views/TallyDetail.vue";
import OrderProcess from "../views/OrderProcess.vue";
import OrderProcessDetail from "../views/OrderProcessDetail.vue";
import OrderProcessCreate from "../views/OrderProcessCreate.vue";
import OrderCashier from "../views/OrderCashier.vue";
import OrderCashierCreate from "../views/OrderCashierCreate.vue";
import OrderCashierDetail from "../views/OrderProcessDetail.vue";
import Manifest from "../views/Manifest.vue";
import ManifestCreate from "../views/ManifestCreate.vue";
import ManifestDetail from "../views/ManifestDetail.vue";
import ManifestEdit from "../views/ManifestEdit.vue";
import Tracking from "../views/Tracking.vue";
import Pricing from "../views/Pricing.vue";
import RouteAndLead from "../views/RouteAndLead.vue";
import RouteAndLeadAdd from "../views/RouteAndLeadAdd.vue";
import RouteAndLeadEdit from "../views/RouteAndLeadEdit.vue";
import VendorManagement from "../views/VendorManagement.vue";
import VendorManagementAdd from "../views/VendorManagementAdd.vue";
import VendorManagementEdit from "../views/VendorManagementEdit.vue";
import Route from "../views/Route.vue";
import RouteAdd from "../views/RouteAdd.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Login",
        meta: {
            layout: "login",
            requiresAuth: false,
        },
        component: Login,
    },
    {
        path: "/forgot/form",
        name: "forgot-password",
        meta: {
            layout: "login",
            requiresAuth: false,
        },
        component: ForgotPassword,
    },
    {
        path: "/forgot/new-password/:id",
        name: "new-password",
        meta: {
            layout: "login",
            requiresAuth: false,
        },
        component: NewPassword,
    },
    {
        path: "/forgot/otp",
        name: "forgot-otp",
        meta: {
            layout: "login",
            requiresAuth: false,
        },
        component: ForgotOtp,
    },
    {
        path: "/dashboard",
        name: "dashboard",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Dashboard,
    },
    {
        path: "/dashboard/tally",
        name: "dashboard-tally",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardTally,
    },
    {
        path: "/dashboard/head-driver",
        name: "dashboard-head-driver",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardHeadDriver,
    },
    {
        path: "/profile",
        name: "admin-profile",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Profile,
    },
    {
        path: "/crm/assign/cro/detail/:id",
        name: "crm-assign-cro-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmAssignCroDetail,
    },
    {
        path: "/crm/customers",
        name: "crm-customers",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomers,
    },
    {
        path: "/crm/customers/ordered",
        name: "crm-customers-ordered",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersOrdered,
    },
    {
        path: "/data/leads",
        name: "crm-customers-leads",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersLeads,
    },
    {
        path: "/data/leads/detail",
        name: "crm-customers-leads-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersLeadsDetail,
    },
    {
        path: "/data/leads/create",
        name: "crm-customers-form",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersForm,
    },
    {
        path: "/data/leads/edit/:id",
        name: "crm-customers-form-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersFormEdit,
    },
    {
        path: "/data/leads/prospect/:id",
        name: "crm-customers-leads-prospect",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomerLeadsProspect,
    },
    {
        path: "/prediction/edit/:id",
        name: "crm-prediction-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPredictionEdit,
    },
    {
        path: "/crm/prospect",
        name: "crm-prospect",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspect,
    },
    {
        path: "/followup/all",
        name: "crm-prospect-all",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectAll,
    },
    {
        path: "/followup/today",
        name: "crm-prospect-today",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectToday,
    },
    {
        path: "/followup/tomorrow",
        name: "crm-prospect-tomorrow",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectTomorrow,
    },
    {
        path: "/followup/next-two-days",
        name: "crm-prospect-next-two-days",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectNextTwoDays,
    },
    {
        path: "/followup/next-week",
        name: "crm-prospect-next-week",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectNextWeek,
    },
    {
        path: "/followup/next-month",
        name: "crm-prospect-next-month",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectNextMonth,
    },
    {
        path: "/followup/need-follow-up",
        name: "crm-prospect-need-follow-up",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectNeedFollowUp,
    },
    {
        path: "/followup/detail",
        name: "crm-prospect-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectDetail,
    },
    {
        path: "/followup/lost/:id",
        name: "crm-prospect-lost",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectLost,
    },
    {
        path: "/followup/deal/:id",
        name: "crm-prospect-deal",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectDealt,
    },
    {
        path: "/crm/profile",
        name: "crm-profile",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProfile,
    },
    {
        path: "/crm/profile/edit",
        name: "crm-profile-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProfileEdit,
    },
    {
        path: "/crm/customers/registered",
        name: "crm-customers-registered",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersRegistered,
    },
    {
        path: "/notification",
        name: "notification",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: NotificationPage,
    },
    {
        path: "/assignment/driver",
        name: "assignment-driver",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: AssignmentDriver,
    },
    {
        path: "/assignment/list",
        name: "assignment-list",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: AssignmentList,
    },
    {
        path: "/assignment/detail",
        name: "assignment-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: AssignmentListDetail,
    },
    {
        path: "/pickup-driver",
        name: "pickup-driver",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: PickupDriver,
    },
    {
        path: "/deal-order",
        name: "deal-order",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DealOrder,
    },
    {
        path: "/lost-order",
        name: "lost-order",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: LostOrder,
    },
    {
        path: "/walk-in",
        name: "walk-in",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: WalkIn,
    },
    {
        path: "/walk-in/create",
        name: "walk-in-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: WalkInCreate,
    },
    {
        path: "/walk-in/detail",
        name: "walk-in-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: WalkInDetail,
    },
    {
        path: "/walk-in/edit",
        name: "walk-in-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: WalkInEdit,
    },
    {
        path: "/tally/off-loading",
        name: "tally-off-loading",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TallyOffLoading,
    },
    {
        path: "/tally/driver/",
        name: "tally-driver",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Tally,
    },
    {
        path: "/tally-detail",
        name: "tally-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TallyDetail,
    },
    {
        path: "/order/process",
        name: "order-process",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: OrderProcess,
    },
    {
        path: "/order/process/detail",
        name: "order-process-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: OrderProcessDetail,
    },
    {
        path: "/order/process/create",
        name: "order-process-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: OrderProcessCreate,
    },
    {
        path: "/order/cashier",
        name: "order-cashier",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: OrderCashier,
    },
    {
        path: "/order/cashier/create",
        name: "order-cashier-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: OrderCashierCreate,
    },
    {
        path: "/order/cashier/detail",
        name: "order-cashier-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: OrderCashierDetail,
    },
    {
        path: "/manifest",
        name: "manifest",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Manifest,
    },
    {
        path: "/manifest/create",
        name: "manifest-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManifestCreate,
    },
    {
        path: "/manifest/detail/:id",
        name: "manifest-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManifestDetail,
    },
    {
        path: "/manifest/edit/:id",
        name: "manifest-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManifestEdit,
    },
    {
        path: "/tracking",
        name: "tracking",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Tracking,
    },
    {
        path: "/pricing",
        name: "pricing",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Pricing,
    },
    {
        path: "/route-and-lead",
        name: "route-and-lead",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: RouteAndLead,
    },
    {
        path: "/route-and-lead/add",
        name: "route-and-lead-add",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: RouteAndLeadAdd,
    },
    {
        path: "/route-and-lead/:id",
        name: "route-and-lead-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: RouteAndLeadEdit,
    },
    {
        path: "/vendor-management",
        name: "vendor-management",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: VendorManagement,
    },
    {
        path: "/vendor-management/add",
        name: "vendor-management-add",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: VendorManagementAdd,
    },
    {
        path: "/vendor-management/:id",
        name: "vendor-management-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: VendorManagementEdit,
    },
    {
        path: "/route",
        name: "route",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Route,
    },
    {
        path: "/route/add",
        name: "route-add",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: RouteAdd,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
