var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-wrapper chiller-theme",class:!_vm.isMobile ? 'toggled' : ''},[_c('notifications',{attrs:{"group":"foo","position":"top right"}}),_vm._m(0),_c('nav',{staticClass:"sidebar-wrapper",attrs:{"id":"sidebar"}},[_c('div',{staticClass:"sidebar-content"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"sidebar-menu"},[_c('ul',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                            _vm.isRole(_vm.prospectRoles) &&
                            (_vm.tier == 'gold' ||
                                _vm.tier == 'platinum' ||
                                _vm.tier == 'enterprise')
                        ),expression:"\n                            isRole(prospectRoles) &&\n                            (tier == 'gold' ||\n                                tier == 'platinum' ||\n                                tier == 'enterprise')\n                        "}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name ==
                                        'crm-customers-leads' ||
                                    this.$route.name ==
                                        'crm-customers-leads-detail' ||
                                    this.$route.name ==
                                        'crm-customers-leads-prospect' ||
                                    this.$route.name ==
                                        'crm-customers-form' ||
                                    this.$route.name ==
                                        'crm-customers-form-edit' ||
                                    this.$route.name ==
                                        'crm-customers-leads-tags',
                            },attrs:{"href":"/data/leads"}},[_c('i',{staticClass:"pi pi-folder"}),_c('span',[_vm._v("Data Leads")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                            _vm.isRole(_vm.prospectRoles) &&
                            (_vm.tier == 'gold' ||
                                _vm.tier == 'platinum' ||
                                _vm.tier == 'enterprise')
                        ),expression:"\n                            isRole(prospectRoles) &&\n                            (tier == 'gold' ||\n                                tier == 'platinum' ||\n                                tier == 'enterprise')\n                        "}],staticClass:"sidebar-dropdown",class:{
                            '': true,
                            active:
                                this.$route.name == 'crm-prospect-today',
                        }},[_c('a',{class:this.$route.name == 'crm-prospect-today'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-tomorrow'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'crm-prospect-all'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-two-days'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-week'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-month'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-need-follow-up'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-detail'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-prediction'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-lost'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-deal'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prediction-edit'
                                    ? 'active'
                                    : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"pi pi-send"}),_c('span',[_vm._v("Follow Up")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu",class:this.$route.name == 'crm-prospect-today'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-tomorrow'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'crm-prospect-all'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-two-days'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-week'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-month'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-need-follow-up'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-prediction'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-lost'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-deal'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prediction-edit'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-today'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'crm-prospect-all'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-tomorrow'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-next-two-days'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-next-week'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-next-month'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-need-follow-up'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-prediction'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-lost'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-deal'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prediction-edit'
                                    ? 'none'
                                    : ''},[_c('ul',[_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'crm-prospect-all',
                                        },attrs:{"href":"/followup/all"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(3),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                        'blue-primary tag-active':
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-all',
                                                        'gray-solid':
                                                            this.$route
                                                                .name !=
                                                            'crm-prospect-all',
                                                    }},[_vm._v(" "+_vm._s(_vm.prospect_count?.all ? _vm.prospect_count?.all : 0)+" ")])])])])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'crm-prospect-today',
                                        },attrs:{"href":"/followup/today"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(4),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                        'blue-primary tag-active':
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-today',
                                                        'gray-solid':
                                                            this.$route
                                                                .name !=
                                                            'crm-prospect-today',
                                                    }},[_vm._v(" "+_vm._s(_vm.prospect_count?.today ? _vm.prospect_count?.today : 0)+" ")])])])])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'crm-prospect-tomorrow',
                                        },attrs:{"href":"/followup/tomorrow"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(5),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                        'blue-primary tag-active':
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-tomorrow',
                                                        'gray-solid':
                                                            this.$route
                                                                .name !=
                                                            'crm-prospect-tomorrow',
                                                    }},[_vm._v(" "+_vm._s(_vm.prospect_count?.yesterday ? _vm.prospect_count?.yesterday : 0)+" ")])])])])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'crm-prospect-next-two-days',
                                        },attrs:{"href":"/followup/next-two-days"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(6),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                        'blue-primary tag-active':
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-next-two-days',
                                                        'gray-solid':
                                                            this.$route
                                                                .name !=
                                                            'crm-prospect-next-two-days',
                                                    }},[_vm._v(" "+_vm._s(_vm.prospect_count?.twodays ? _vm.prospect_count?.twodays : 0)+" ")])])])])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'crm-prospect-next-week',
                                        },attrs:{"href":"/followup/next-week"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(7),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                        'blue-primary tag-active':
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-next-week',
                                                        'gray-solid':
                                                            this.$route
                                                                .name !=
                                                            'crm-prospect-next-week',
                                                    }},[_vm._v(" "+_vm._s(_vm.prospect_count?.lastweek ? _vm.prospect_count?.lastweek : 0)+" ")])])])])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'crm-prospect-next-month',
                                        },attrs:{"href":"/followup/next-month"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(8),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                        'blue-primary tag-active':
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-next-month',
                                                        'gray-solid':
                                                            this.$route
                                                                .name !=
                                                            'crm-prospect-next-month',
                                                    }},[_vm._v(" "+_vm._s(_vm.prospect_count?.lastmonth ? _vm.prospect_count?.lastmonth : 0)+" ")])])])])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'crm-prospect-need-follow-up',
                                        },attrs:{"href":"/followup/need-follow-up"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(9),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                        'blue-primary tag-active':
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-need-follow-up',
                                                        'gray-solid':
                                                            this.$route
                                                                .name !=
                                                            'crm-prospect-need-follow-up',
                                                    }},[_vm._v(" "+_vm._s(_vm.prospect_count?.need_followup ? _vm.prospect_count?.need_followup : 0)+" ")])])])])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                            _vm.isRole(_vm.headDriverRoles) && _vm.tier == 'enterprise'
                        ),expression:"\n                            isRole(headDriverRoles) && tier == 'enterprise'\n                        "}],staticClass:"sidebar-dropdown",class:{
                            '': true,
                            active: this.$route.name == 'assignment-driver',
                        }},[_c('a',{class:this.$route.name == 'assignment-driver'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'assignment-list'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'assignment-detail'
                                    ? 'active'
                                    : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"pi pi-user"}),_c('span',[_vm._v("Head Driver")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu",class:this.$route.name == 'assignment-driver'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'assignment-list'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'assignment-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name !=
                                          'assignment-driver'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'assignment-list'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'assignment-detail'
                                    ? 'none'
                                    : ''},[_c('ul',[_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'assignment-driver',
                                        },attrs:{"href":"/assignment/driver"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Penugasan Driver ")])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                    'assignment-list' ||
                                                this.$route.name ==
                                                    'assignment-detail',
                                        },attrs:{"href":"/assignment/list"}},[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Daftar Penugasan ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.driverRoles) && _vm.tier == 'enterprise'),expression:"isRole(driverRoles) && tier == 'enterprise'"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active: this.$route.name == 'pickup-driver',
                            },attrs:{"href":"/pickup-driver"}},[_c('i',{staticClass:"pi pi-tablet"}),_c('span',[_vm._v("Driver Jemput")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                            _vm.isRole(_vm.prospectRoles) &&
                            (_vm.tier == 'gold' ||
                                _vm.tier == 'platinum' ||
                                _vm.tier == 'enterprise')
                        ),expression:"\n                            isRole(prospectRoles) &&\n                            (tier == 'gold' ||\n                                tier == 'platinum' ||\n                                tier == 'enterprise')\n                        "}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'deal-order' ||
                                    this.$route.name == 'deal-order-detail',
                            },attrs:{"href":"/deal-order"}},[_c('i',{staticClass:"pi pi-thumbs-up"}),_c('span',[_vm._v("Deal Order")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                            _vm.isRole(_vm.prospectRoles) &&
                            (_vm.tier == 'gold' ||
                                _vm.tier == 'platinum' ||
                                _vm.tier == 'enterprise')
                        ),expression:"\n                            isRole(prospectRoles) &&\n                            (tier == 'gold' ||\n                                tier == 'platinum' ||\n                                tier == 'enterprise')\n                        "}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active: this.$route.name == 'lost-order',
                            },attrs:{"href":"/lost-order"}},[_c('i',{staticClass:"pi pi-ban"}),_c('span',[_vm._v("Lost Order")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.tallyRoles) && _vm.tier == 'enterprise'),expression:"isRole(tallyRoles) && tier == 'enterprise'"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'walk-in' ||
                                    this.$route.name == 'walk-in-create' ||
                                    this.$route.name == 'walk-in-detail' ||
                                    this.$route.name == 'walk-in-edit',
                            },attrs:{"href":"/walk-in"}},[_c('i',{staticClass:"pi pi-file"}),_c('span',[_vm._v("Pesanan Walk In")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.tallyRoles) && _vm.tier == 'enterprise'),expression:"isRole(tallyRoles) && tier == 'enterprise'"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'tally-driver' ||
                                    this.$route.name ==
                                        'tally-off-loading' ||
                                    this.$route.name == 'tally-detail',
                            },attrs:{"href":"/tally/off-loading"}},[_c('i',{staticClass:"pi pi-box"}),_c('span',[_vm._v("Tally")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                            _vm.isRole(_vm.claimOrder) &&
                            (_vm.tier == 'gold' ||
                                _vm.tier == 'platinum' ||
                                _vm.tier == 'enterprise')
                        ),expression:"\n                            isRole(claimOrder) &&\n                            (tier == 'gold' ||\n                                tier == 'platinum' ||\n                                tier == 'enterprise')\n                        "}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'order-process' ||
                                    this.$route.name ==
                                        'order-process-detail' ||
                                    this.$route.name ==
                                        'order-process-create',
                            },attrs:{"href":"/order/process"}},[_c('i',{staticClass:"pi pi-file"}),_c('span',[_vm._v("Klaim Pesanan")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                            _vm.isRole(_vm.orderRoles) &&
                            (_vm.tier == 'silver' ||
                                _vm.tier == 'gold' ||
                                _vm.tier == 'platinum' ||
                                _vm.tier == 'enterprise')
                        ),expression:"\n                            isRole(orderRoles) &&\n                            (tier == 'silver' ||\n                                tier == 'gold' ||\n                                tier == 'platinum' ||\n                                tier == 'enterprise')\n                        "}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'order-cashier' ||
                                    this.$route.name ==
                                        'order-cashier-create' ||
                                    this.$route.name ==
                                        'order-cashier-detail',
                            },attrs:{"href":"/order/cashier"}},[_c('i',{staticClass:"pi pi-file"}),_c('span',[_vm._v("Daftar Pesanan")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                            _vm.isRole(_vm.manifestRole) &&
                            (_vm.tier == 'enterprise' || _vm.tier == 'platinum')
                        ),expression:"\n                            isRole(manifestRole) &&\n                            (tier == 'enterprise' || tier == 'platinum')\n                        "}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active: this.$route.name == 'tracking',
                            },attrs:{"href":"/tracking"}},[_c('i',{staticClass:"pi pi-map-marker"}),_c('span',[_vm._v("Tracking")])])]),(
                            _vm.isRole(_vm.manifestRole) &&
                            (_vm.tier == 'platinum' || _vm.tier == 'enterprise')
                        )?_c('li',{staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'manifest' ||
                                    this.$route.name == 'manifest-create' ||
                                    this.$route.name == 'manifest-edit' ||
                                    this.$route.name == 'manifest-detail',
                            },attrs:{"href":"/manifest"}},[_c('i',{staticClass:"pi pi-book"}),_c('span',[_vm._v("Daftar Manifest")])])]):_vm._e(),_c('li',{staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'route-and-lead' ||
                                    this.$route.name ==
                                        'route-and-lead-add',
                            },attrs:{"href":"/route-and-lead"}},[_c('i',{staticClass:"fa fa-clock-o"}),_c('span',[_vm._v("Route Management & Lead Time")])])]),_c('li',{staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                active: [
                                    'vendor-management',
                                    'vendor-management-add',
                                    'vendor-management-edit',
                                    'route',
                                    'route-add',
                                ].includes(this.$route.name),
                            },attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"pi pi-send"}),_c('span',[_vm._v("Vendor")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu",class:{
                                block:
                                    this.$route.name ===
                                        'vendor-management' ||
                                    this.$route.name ===
                                        'vendor-management-add' ||
                                    this.$route.name ===
                                        'vendor-management-edit' ||
                                    this.$route.name === 'route' ||
                                    this.$route.name === 'route-add',
                                none:
                                    this.$route.name !==
                                        'vendor-management' &&
                                    this.$route.name !==
                                        'vendor-management-edit' &&
                                    this.$route.name !==
                                        'vendor-management-add' &&
                                    this.$route.name !== 'route' &&
                                    this.$route.name !== 'route-add',
                            }},[_c('ul',[_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                    'vendor-management' ||
                                                this.$route.name ==
                                                    'vendor-management-add' ||
                                                this.$route.name ==
                                                    'vendor-management-edit',
                                        },attrs:{"href":"/vendor-management"}},[_vm._m(10)])]),_c('li',[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                    'route' ||
                                                this.$route.name ==
                                                    'route-add',
                                        },attrs:{"href":"/route"}},[_vm._m(11)])])])])]),_c('li',{staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active: this.$route.name == 'pricing',
                            },attrs:{"href":"/pricing"}},[_c('i',{staticClass:"fa fa-tags"}),_c('span',[_vm._v("Pricing")])])])]),_vm._m(12)])])]),_c('main',{staticClass:"page-content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"pl-40 pr-40 position-relative pt-3"},[_c('div',{staticClass:"nav-top-main"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h3',{staticClass:"size-16 fw-semibold mb-0 capitalize d-none d-lg-block"},[_vm._v(" "+_vm._s(_vm.welcomeMessage)+" ")]),_c('div',{staticClass:"ms-auto"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"dropdown"},[_c('div',{staticClass:"notifications toggle dd-trigger me-4 cursor-pointer"},[_c('span',{staticClass:"count bg-yellow",attrs:{"id":"notifications-count"}},[_vm._v(_vm._s(_vm.totalNotif))]),_c('span',{staticClass:"fa fa-bell-o size-22"})]),_c('div',{staticClass:"dropdown-menu w-40"},[_c('div',{staticClass:"d-flex items-align-center justify-content-between mb-4"},[_c('div',{staticClass:"size-16 fw-semibold"},[_vm._v(" Notifikasi ")]),(
                                                    _vm.notif_all.length !== 0
                                                )?_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.readNotif()}}},[_c('u',[_vm._v("Tandai semua telah dibaca")])]):_vm._e()]),_c('div',{class:{
                                                'scroll-notif':
                                                    _vm.notif_all.length > 4,
                                            }},_vm._l((_vm.notif_all),function(notif){return _c('a',{key:notif.id,attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.readNotif(
                                                        notif.id,
                                                        notif.meta.path
                                                    )}}},[_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('div',{staticClass:"me-3"},[_c('img',{staticClass:"user-notif",attrs:{"src":notif.status ===
                                                                'pending'
                                                                    ? '../assets/bell-unread.png'
                                                                    : '../assets/bell-read.png',"alt":"notification bell"}})]),_c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"size-16 fw-semibold",class:notif.status ===
                                                                    'pending'
                                                                        ? 'title-unread'
                                                                        : 'text-gray'},[_vm._v(" "+_vm._s(notif.title)+" ")]),_c('div',{staticClass:"ms-auto text-gray size-12"},[_vm._v(" "+_vm._s(notif.created_at)+" ")])]),_c('div',{class:notif.status ===
                                                                'pending'
                                                                    ? 'subtitle-unread'
                                                                    : 'text-gray'},[_vm._v(" "+_vm._s(notif.subtitle)+" ")])])])])}),0),(_vm.notif_all.length === 0)?_c('div',{staticClass:"my-4"},[_c('center',[_c('img',{attrs:{"src":require("../assets/no-notif.png"),"width":"120","alt":""}}),_c('div',{staticClass:"mt-3 text-gray-dashboard"},[_vm._v(" Belum ada notifikasi ")])])],1):_vm._e(),(_vm.notif_all.length !== 0)?_c('hr'):_vm._e(),(_vm.notif_all.length !== 0)?_c('div',{staticClass:"text-center"},[_vm._m(13)]):_vm._e()])]),_c('div',{staticClass:"dropdown-profile"},[_c('div',{staticClass:"toggle-profile cursor-pointer"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"me-2"},[(
                                                        _vm.user.avatar != null
                                                    )?[_c('div',{staticClass:"user-avatar-sidebar user-small",style:({
                                                            'background-image':
                                                                'url(' +
                                                                _vm.user.avatar +
                                                                ')',
                                                        })})]:[_c('div',{staticClass:"user-avatar-sidebar user-small",style:({
                                                            'background-image':
                                                                'url(' +
                                                                require('../assets/user.jpg') +
                                                                ')',
                                                        })})]],2),_c('div',{staticClass:"fw-medium size-14",staticStyle:{"line-height":"17px"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_vm._m(14)])])])]),_c('div',{staticClass:"dropdown-menu-profile"},[_c('router-link',{attrs:{"to":{ name: _vm.profileRole() }}},[_c('a',{staticClass:"size-14 fw-semibold text-dark"},[_vm._v(" Edit Profile ")])]),_c('hr'),_c('a',{staticClass:"text-danger size-14 fw-semibold",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.logout()}}},[_c('i',{staticClass:"fa fa-sign-out me-1"}),_vm._v(" Log Out ")])],1)])])])])])]),_c('router-view',{staticClass:"mt-content",attrs:{"message":this.$attrs.message,"totalNotif":_vm.totalNotif,"roles":_vm.roles}})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn btn-sm btn-primary",attrs:{"id":"show-sidebar"}},[_c('i',{staticClass:"fa fa-angle-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-brand d-block d-lg-none"},[_c('div',{attrs:{"id":"close-sidebar"}},[_c('img',{attrs:{"src":require("../assets/arrow-back.png"),"width":"30","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-header"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"width":"150","alt":"logo klik"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Semua Follow Up ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Hari Ini ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Kemarin ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" 2 Hari Lalu ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Minggu Lalu ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Bulan Lalu ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Perlu Follow Up ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Manajemen Vendor ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Rute & Keberangkatan ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"powered"},[_c('div',{staticClass:"powered-content"},[_c('div',{staticClass:"mt-3 text-gray-dashboard size-13"},[_vm._v(" Powered By ")]),_c('img',{attrs:{"src":require("../assets/logo-tb-new.png"),"width":"130","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-green",attrs:{"href":"/notification"}},[_vm._v(" Lihat Semua Notifikasi "),_c('i',{staticClass:"fa fa-angle-right ms-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ms-2"},[_c('i',{staticClass:"fa fa-angle-down ms-2 size-16 fw-bold"})])
}]

export { render, staticRenderFns }