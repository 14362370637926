<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <h5 class="fw-bold mb-4">Tambah Vendor</h5>
            <div class="row">
                <div class="col-md-6 mb-4">
                    <label class="mb-2"
                        >Nama Vendor <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama vendor"
                        v-model="detailVendor.name"
                    />
                </div>
                <div class="col-md-6 mb-4">
                    <label class="mb-2"
                        >Nomor Telepon <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nomor telepon"
                        v-model="detailVendor.phone"
                    />
                </div>
                <div class="col-md-6 mb-4">
                    <label class="mb-2"
                        >Email <span class="text-yellow">*</span></label
                    >
                    <input
                        type="email"
                        class="form-control"
                        placeholder="Masukkan email"
                        v-model="detailVendor.email"
                    />
                </div>
                <div class="col-md-12 mb-4">
                    <label class="mb-2"
                        >Alamat Vendor <span class="text-yellow">*</span></label
                    >
                    <textarea
                        class="form-control"
                        placeholder="Masukkan alamat vendor"
                        v-model="detailVendor.address"
                    ></textarea>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <button
                    class="btn btn-primary px-4"
                    :disabled="
                        !detailVendor.name ||
                        !detailVendor.phone ||
                        !detailVendor.email ||
                        !detailVendor.address
                    "
                    @click="modalConfirm = true"
                >
                    Simpan Perubahan
                </button>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Perubahan data vendor akan disimpan
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="editData()"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Perubahan data vendor berhasil disimpan
                    </h4>
                </div>
                <router-link
                    to="/vendor-management"
                    href="javascript:void(0)"
                    class="btn btn-primary w-100 btn-lg mt-4"
                >
                    Tutup
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import magnifierDisabled from "../assets/magnifier-disabled.svg";
import magnifier from "../assets/magnifier.svg";

export default {
    data() {
        return {
            isPageLoading: false,
            magnifierIcon: magnifierDisabled,
            magnifierActiveIcon: magnifier,
            modalConfirm: false,
            modalSuccess: false,
            detailVendor: {
                name: null,
                phone: null,
                email: null,
                address: null,
            },
            isLoading: false,
        };
    },
    created() {
        this.getDetailVendor();
    },
    methods: {
        getDetailVendor() {
            this.isPageLoading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/vendors/detail`, {
                params: {
                    vendor_id: this.$route.params.id,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.detailVendor = res.data.data;
                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.isPageLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        editData() {
            this.isLoading = true;
            var data = {
                vendor_id: this.$route.params.id,
                name: this.detailVendor.name,
                phone: this.detailVendor.phone,
                email: this.detailVendor.email,
                address: this.detailVendor.address,
            };

            Api.post(`${process.env.VUE_APP_SERVICE_URL}/vendors/edit`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then(() => {
                    this.isLoading = false;
                    this.modalSuccess = true;
                    this.modalConfirm = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.outline-add {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
}
.rotate-180 {
    transform: rotate(180deg);
}
.transition-transform {
    transition: transform 0.3s ease;
}
.outline-yellow {
    background-color: transparent !important;
    border: 1px solid #ff8600 !important;
    color: #ff8600 !important;
}
.b-l-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
::v-deep .multi-form-custom.magnifier {
    .multiselect__tags {
        padding-left: 40px !important;
    }
    .multiselect__select {
        padding: 0;
    }
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    cursor: pointer;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.bb-r {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
</style>
